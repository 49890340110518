import React from 'react';
import styles from './App.module.scss';
import HomeView from './views/home/home.view';
import { Route, Routes } from 'react-router-dom';
import PushNotificationsView from './views/push-notifications/push-notifications.view';
import LoginView from './views/login/login.view';
import ProtectedRoute from './components/protected-route/protected-route.component';
import NotFoundView from './views/not-found/not-found.view';

function App() {
    return (
        <div id={styles.app}>
            <Routes>
                <Route path={'login'} element={<LoginView />} />
                {/* Authenticated routes*/}
                <Route element={<ProtectedRoute />}>
                    <Route path={'/'} element={<HomeView />} />
                    <Route path={'push'} element={<PushNotificationsView />} />
                </Route>
                <Route path={'*'} element={<NotFoundView />} />
            </Routes>
        </div>
    );
}

export default App;
