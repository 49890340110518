import { useEffect, useState } from 'react';
import styles from './scheduling-picker.module.scss';
import DatePicker from '../date-picker/date-picker.component';
import TimePicker from '../time-picker/time-picker.component';
import { format } from 'date-fns';

interface Props {
    onDateChange: (date: string) => void;
    onTimeChange: (date: string) => void;
}

const SchedulingPicker = (props: Props) => {
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [timePickerVisible, setTimePickerVisible] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(
        format(new Date(), 'yyyy-MM-dd')
    );
    const [scheduledTime, setScheduledTime] = useState(
        new Date().toLocaleTimeString('es-ES', {
            hour: '2-digit',
            minute: '2-digit',
        })
    );

    useEffect(() => {
        props.onDateChange(scheduledDate);
    }, [scheduledDate]);

    useEffect(() => {
        props.onTimeChange(scheduledTime);
    }, [scheduledTime]);

    const showDatePicker = () => {
        setDatePickerVisible(true);
    };

    const showTimePicker = () => {
        setTimePickerVisible(true);
    };

    const onDatePicked = (date: Date) => {
        setScheduledDate(format(date, 'yyyy-MM-dd'));
        setDatePickerVisible(false);
    };

    const onTimePicked = (time: string) => {
        setScheduledTime(time);
    };

    return (
        <div className={styles.container}>
            <input
                className={styles.dateInput}
                onFocus={showDatePicker}
                type={'text'}
                readOnly={true}
                value={scheduledDate || ''}
            />
            <input
                className={styles.timeInput}
                type={'text'}
                readOnly={true}
                value={scheduledTime || '12:00'}
                onClick={showTimePicker}
            />
            <DatePicker
                visible={datePickerVisible}
                onChange={onDatePicked}
                pastAllowed={false}
                onBlur={() => setDatePickerVisible(false)}
            />
            <TimePicker
                visible={timePickerVisible}
                onBlur={() => setTimePickerVisible(false)}
                oChange={onTimePicked}
            />
        </div>
    );
};

export default SchedulingPicker;
