import React from 'react';
import styles from './input.module.scss';
import { useState } from 'react';

interface Props {
    label: string;
    placeholder: string;
    type: 'text' | 'number' | 'email' | 'password' | 'textarea';
    readonly?: boolean;
    className?: string;
    maxlength?: number;
    onChange?: (value: any) => void;
}

const Input = (props: Props) => {
    const [length, setLength] = useState(0);

    const handleChange = (
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (props.maxlength) {
            setLength(ev.target.value.length);
        }

        props.onChange?.(ev.target.value);
    };

    return (
        <div className={`${styles.container} ${props.className}`}>
            <label>{props.label}</label>
            {props.type === 'textarea' ? (
                <textarea
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    onChange={handleChange}
                    maxLength={props.maxlength}
                />
            ) : (
                <input
                    type={props.type}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    onChange={handleChange}
                    maxLength={props.maxlength}
                />
            )}
            {props.maxlength ? (
                <span className={styles.inputLength}>
                    {length} / {props.maxlength}
                </span>
            ) : (
                ''
            )}
        </div>
    );
};

export default Input;
