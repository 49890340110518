import axios, { Axios, AxiosError } from 'axios';
import IWebserviceResponse from '../models/webservice-response.model';

export default class Webservice {
    protected httpClient: Axios;

    constructor() {
        this.httpClient = axios;
    }

    protected handleResponseError(error: AxiosError): IWebserviceResponse {
        if (!error.response) {
            console.error('[!] Internet connection error');
            return {
                code: -999,
                message: 'Internet connection error',
            };
        }

        return error.response.data as IWebserviceResponse;
    }
}
