import Webservice from './webservice';
import IWebserviceResponse from '../models/webservice-response.model';
import ILogin from '../models/login.model';
import { AxiosRequestConfig } from 'axios';
import IPushNotificationCreation from '../models/push-notification-creation.model';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export default class PnsWebservice extends Webservice {
    async logIn(credentials: ILogin): Promise<IWebserviceResponse> {
        try {
            const response = await this.httpClient.post(
                `${BASE_URL}/auth`,
                credentials
            );
            return response.data;
        } catch (err: any) {
            console.error('PnsWebservice error:', err);
            return this.handleResponseError(err);
        }
    }

    async publishNotificationJob(
        notification: IPushNotificationCreation
    ): Promise<IWebserviceResponse> {
        try {
            const form = new FormData();
            form.append('notificationTitle', notification.notificationTitle);
            form.append('notificationBody', notification.notificationBody);
            form.append('scheduledDate', notification.scheduledDate);
            form.append('file', notification.file);

            const opts: AxiosRequestConfig = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'accessToken'
                    )}`,
                },
            };

            const response = await this.httpClient.post(
                `${BASE_URL}/notifications/batch`,
                form,
                opts
            );
            return response.data;
        } catch (err: any) {
            console.error('PnsWebservice error:', err);
            return this.handleResponseError(err);
        }
    }
}
