interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
}

const PushIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 19}
            viewBox="0 0 26 20"
            fill="none"
            className={props.className}
        >
            <path
                d="M11.4639 7.53571L11.8769 6.625L10.466 5.98507L10.4639 7.53436L11.4639 7.53571ZM11.4584 11.6052L10.4584 11.6038V11.6052H11.4584ZM11.4584 13.9306L10.4584 13.9306L10.4584 13.9319L11.4584 13.9306ZM11.4639 18L10.4639 18.0014L10.466 19.5506L11.8769 18.9107L11.4639 18ZM23.0001 12.7679L23.4131 13.6786L25.4211 12.7679L23.4131 11.8571L23.0001 12.7679ZM10.4639 7.53436L10.4584 11.6038L12.4584 11.6065L12.4639 7.53706L10.4639 7.53436ZM10.4584 11.6052V12.7679H12.4584V11.6052H10.4584ZM10.4584 12.7679V13.9306H12.4584V12.7679H10.4584ZM10.4584 13.9319L10.4639 18.0014L12.4639 17.9986L12.4584 13.9292L10.4584 13.9319ZM11.8769 18.9107L23.4131 13.6786L22.587 11.8571L11.0508 17.0893L11.8769 18.9107ZM23.4131 11.8571L11.8769 6.625L11.0508 8.44642L22.587 13.6786L23.4131 11.8571Z"
                fill={props.color || 'black'}
            />
            <path
                d="M11.0026 11L11 12.3611V12.75V13.1389L11.0026 14.5L16.4444 12.75L11.0026 11Z"
                fill={props.color || 'black'}
            />
            <path
                d="M5.35 4.04C6.6 1.64 9.11 0 12 0C15.64 0 18.67 2.59 19.35 6.04C21.95 6.22 24 8.36 24 11C24 11.0183 23.9999 11.0365 23.9997 11.0548L21.872 10.1429C21.5204 8.99272 20.4813 8.12085 19.22 8.04L17.69 7.93L17.39 6.43C16.88 3.86 14.62 2 12 2C9.94 2 8.08 3.14 7.13 4.97L6.63 5.92L5.56 6.03C3.53 6.24 2 7.95 2 10C2 12.21 3.79 14 6 14H9.5V16H6C2.69 16 0 13.31 0 10C0 6.91 2.34 4.36 5.35 4.04Z"
                fill={props.color || 'black'}
            />
        </svg>
    );
};

export default PushIcon;
