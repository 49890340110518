interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
}

const CalendarIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 24 24"
            fill="none"
            className={props.className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V10H20V21ZM4 8H20V5H4V8Z"
                    fill={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default CalendarIcon;
