import { useEffect, useRef, useState } from 'react';
import styles from './time-picker.module.scss';
import UpArrowIcon from '../../icons/up-arrow.icon';
import DownArrowIcon from '../../icons/down-arrow.icon';

const HOURS_LIST = Array.from({ length: 24 }, (_, i) => i);

const MINUTES_LIST = Array.from(Array(60).keys());

interface Props {
    visible: boolean;
    onBlur: () => void;
    oChange: (time: string) => void;
}

const TimePicker = (props: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const hoursListRef = useRef<HTMLUListElement | null>(null);
    const minutesListRef = useRef<HTMLUListElement | null>(null);

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
        if (props.visible) {
            containerRef.current?.focus();
        }
    }, [props.visible]);

    const goUpHours = () => {
        const marginTop = Number(
            hoursListRef.current!.style.marginTop.replace('px', '')
        );
        if (marginTop >= 0) return;
        hoursListRef.current!.style.marginTop = `${marginTop + 20}px`;
        setHours(hours - 1);
    };

    const goDownHours = () => {
        const marginTop = Number(
            hoursListRef.current!.style.marginTop.replace('px', '')
        );
        if (marginTop <= -20 * 23) return;
        hoursListRef.current!.style.marginTop = `${marginTop - 20}px`;
        setHours(hours + 1);
    };

    const goUpMinutes = () => {
        const marginTop = Number(
            minutesListRef.current!.style.marginTop.replace('px', '')
        );
        if (marginTop >= 0) return;
        minutesListRef.current!.style.marginTop = `${marginTop + 20}px`;
        setMinutes(minutes - 1);
    };

    const goDownMinutes = () => {
        const marginTop = Number(
            minutesListRef.current!.style.marginTop.replace('px', '')
        );
        if (marginTop <= -20 * 59) return;
        minutesListRef.current!.style.marginTop = `${marginTop - 20}px`;
        setMinutes(minutes + 1);
    };

    const confirmTime = (ev: React.MouseEvent) => {
        ev.preventDefault();

        const hour = `0${hours}`.slice(-2);
        const min = `0${minutes}`.slice(-2);
        props.oChange(`${hour}:${min}`);
        props.onBlur();
    };

    return (
        <div
            className={`${styles.mainContainer} ${
                props.visible ? styles.visible : ''
            }`}
            ref={containerRef}
            tabIndex={-1}
            onBlur={() => props.onBlur()}
        >
            <div className={styles.selectionContainer}>
                <div className={styles.iconContainer} onClick={goUpHours}>
                    <UpArrowIcon className={styles.icon} />
                </div>
                <div className={styles.listContainer}>
                    <ul ref={hoursListRef}>
                        {HOURS_LIST.map((hour, idx) => (
                            <li key={idx}>{`0${hour}`.slice(-2)}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.iconContainer} onClick={goDownHours}>
                    <DownArrowIcon className={styles.icon} />
                </div>
            </div>

            <p>:</p>

            <div className={styles.selectionContainer}>
                <div className={styles.iconContainer} onClick={goUpMinutes}>
                    <UpArrowIcon className={styles.icon} />
                </div>
                <div className={styles.listContainer}>
                    <ul ref={minutesListRef}>
                        {MINUTES_LIST.map((min, idx) => (
                            <li key={idx}>{`0${min}`.slice(-2)}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.iconContainer} onClick={goDownMinutes}>
                    <DownArrowIcon className={styles.icon} />
                </div>
            </div>

            <div className={styles.confirmButton} onClick={confirmTime}>
                Confirmar
            </div>
        </div>
    );
};

export default TimePicker;
