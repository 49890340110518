import styles from './protected-route.module.scss';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Menu from '../menu/menu.component';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/context';

const ProtectedRoute = () => {
    const navigate = useNavigate();
    const { globalState, dispatch } = useContext(GlobalContext);

    useEffect(() => {
        if (!globalState.accessToken) {
            navigate('/login', { replace: true });
        }
    }, [globalState.accessToken]);

    const onLogout = () => {
        dispatch({ action: 'logout', data: null });
    };

    return globalState.accessToken ? (
        <div id={styles.layout}>
            <Menu onLogout={onLogout} />
            <Outlet />
        </div>
    ) : (
        <Navigate to={'/login'} replace />
    );
};

export default ProtectedRoute;
