import React, { useEffect, useState } from 'react';
import styles from './push-notification-review-modal.module.scss';
import PhoneIcon from '../../icons/phone.icon';
import CalendarIcon from '../../icons/calendar.icon';
import TargetIcon from '../../icons/target.icon';
import IPushNotificationForm from '../../models/push-notification-form.model';

interface Props {
    shown: boolean;
    notificationForm: IPushNotificationForm;
    onDiscard: () => void;
    onPublish: (notification: IPushNotificationForm) => void;
}

const PushNotificationReviewModal = (props: Props) => {
    const [publishButtonText, setPublishButtonText] = useState('Publicar');
    const [publishButtonEnabled, setPublishButtonEnabled] = useState(true);

    useEffect(() => {
        if (props.shown) {
            setPublishButtonText('Publicar');
            setPublishButtonEnabled(true);
        }
    }, [props.shown]);

    const onBackgroundClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        const target = ev.target as HTMLElement;
        if (target.tagName === 'SECTION') {
            props.onDiscard();
        }
    };

    const onDiscard = (ev: React.MouseEvent) => {
        ev.preventDefault();
        props.onDiscard();
    };

    const requestPublishing = (ev: React.MouseEvent) => {
        ev.preventDefault();
        setPublishButtonEnabled(false);
        setPublishButtonText('Publicando...');
        props.onPublish(props.notificationForm);
    };

    return (
        <section
            className={`${styles.background} ${
                props.shown ? styles.shown : ''
            }`}
            onClick={onBackgroundClick}
        >
            <div className={styles.modal}>
                <header>
                    <h3>Revisión de mensaje</h3>
                </header>

                <div className={styles.content}>
                    {/* Notification title & body */}
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <PhoneIcon
                                className={styles.icon}
                                color={'#384762'}
                            />
                        </div>
                        <div className={styles.col}>
                            <h4>Notificación</h4>
                            <p>{props.notificationForm.notificationTitle}</p>
                            <p>{props.notificationForm.notificationBody}</p>
                        </div>
                    </div>

                    {/* Notification segment's file */}
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <TargetIcon
                                className={styles.icon}
                                color={'#384762'}
                            />
                        </div>
                        <div className={styles.col}>
                            <h4>Segmento</h4>
                            <p>{props.notificationForm.file?.name}</p>
                        </div>
                    </div>

                    {/* Notification scheduling */}
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <CalendarIcon
                                className={styles.icon}
                                color={'#384762'}
                            />
                        </div>
                        <div className={styles.col}>
                            <h4>Programación</h4>
                            <p>
                                {`${props.notificationForm.scheduledDate} ${props.notificationForm.scheduledTime}`}
                            </p>
                        </div>
                    </div>
                </div>

                <footer>
                    <button className={styles.negative} onClick={onDiscard}>
                        Cancelar
                    </button>
                    <button
                        className={styles.positive}
                        disabled={!publishButtonEnabled}
                        onClick={requestPublishing}
                    >
                        {publishButtonText}
                    </button>
                </footer>
            </div>
        </section>
    );
};

export default PushNotificationReviewModal;
