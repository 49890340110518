interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
}

const EmailIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            className={props.className}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM12 13L4 8V18H20V8L12 13Z"
                    fill={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default EmailIcon;
