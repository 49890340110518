import React from 'react';
import { GlobalStateType, Payload, globalState, reducer } from './state';

export interface GlobalContextType {
    globalState: GlobalStateType;
    dispatch: React.Dispatch<Payload>;
}

export const GlobalContext: React.Context<GlobalContextType> =
    React.createContext<GlobalContextType>({
        globalState: globalState,
        dispatch: () => null,
    });

export const GlobalProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = React.useReducer(reducer, globalState);

    return (
        <GlobalContext.Provider value={{ globalState: state, dispatch }}>
            {children}
        </GlobalContext.Provider>
    );
};
