import styles from './home.module.scss';
import SMSImage from '../../assets/img/sms.png';
import PushImage from '../../assets/img/push-notification.png';
import ServiceCard from '../../components/service-card/service-card.component';
import { useNavigate } from 'react-router-dom';

const HomeView = () => {
    const navigate = useNavigate();

    const goToPushNotificationsView = () => {
        navigate('/push');
    };

    return (
        <main id={styles.home}>
            <header>
                <article>
                    <h1>Servicio de Mensajería</h1>
                    <p>
                        Envío de notificaciones push, <br />
                        mensajes de texto (SMS) y <br />
                        próximamente emails
                    </p>
                </article>
            </header>

            <section className={styles.content}>
                <ServiceCard
                    active={true}
                    title={'Mensajería SMS'}
                    content={
                        'Envío de mensajes de texto a través del protocolo SMS gracias a Twilio.'
                    }
                    img={SMSImage}
                />

                <ServiceCard
                    active={true}
                    title={'Notificaciones Push'}
                    content={
                        'Envío masivo de notificaciones push a los dispositivos Android y iOS.'
                    }
                    img={PushImage}
                    onClick={goToPushNotificationsView}
                />

                <ServiceCard
                    active={false}
                    title={'Emails (inhabilitado)'}
                    content={
                        'Envío de correos electrónicos a nuestra base de datos de usuarios.'
                    }
                    img={PushImage}
                />
            </section>
        </main>
    );
};

export default HomeView;
