interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
}

const SmsIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 24 24"
            fill="none"
            className={props.className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM6 16L4 18V4H20V16H6ZM9 9H7V11H9V9ZM11 9H13V11H11V9ZM17 9H15V11H17V9Z"
                    fill={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default SmsIcon;
