interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
    strokeColor?: string;
}

const LeftArrowIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 9}
            height={props.height || 23}
            viewBox="0 0 9 23"
            className={props.className}
            fill="none"
        >
            <path
                d="M1 12.3571L0.205995 12.9651C-0.0505419 12.63 -0.0691285 12.1699 0.159541 11.8153L1 12.3571ZM7.15954 0.958125C7.45881 0.493952 8.0777 0.360271 8.54187 0.659541C9.00605 0.95881 9.13973 1.5777 8.84046 2.04187L7.15954 0.958125ZM8.79401 20.8921C9.12975 21.3306 9.04643 21.9583 8.60791 22.294C8.16939 22.6297 7.54174 22.5464 7.206 22.1079L8.79401 20.8921ZM0.159541 11.8153L7.15954 0.958125L8.84046 2.04187L1.84046 12.899L0.159541 11.8153ZM1.79401 11.7492L8.79401 20.8921L7.206 22.1079L0.205995 12.9651L1.79401 11.7492Z"
                fill={props.color || '#585970'}
                strokeWidth={props.strokeWidth || 1}
                stroke={'white'}
            />
        </svg>
    );
};

export default LeftArrowIcon;
