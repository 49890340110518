interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
}

const UpArrowIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 22}
            height={props.height || 10}
            className={props.className}
            viewBox="0 0 22 10"
            fill="none"
        >
            <path
                d="M10.1429 1.5L9.53495 0.705995C9.87001 0.449459 10.3301 0.430872 10.6847 0.659542L10.1429 1.5ZM21.5419 7.65954C22.006 7.95881 22.1397 8.5777 21.8405 9.04187C21.5412 9.50605 20.9223 9.63973 20.4581 9.34046L21.5419 7.65954ZM1.60791 9.29401C1.16939 9.62975 0.541735 9.54643 0.205995 9.10791C-0.129745 8.6694 -0.0464271 8.04174 0.39209 7.706L1.60791 9.29401ZM10.6847 0.659542L21.5419 7.65954L20.4581 9.34046L9.60098 2.34046L10.6847 0.659542ZM10.7508 2.29401L1.60791 9.29401L0.39209 7.706L9.53495 0.705995L10.7508 2.29401Z"
                fill={props.color || '#585970'}
            />
        </svg>
    );
};

export default UpArrowIcon;
