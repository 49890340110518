interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
}

const TargetIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 24 24"
            fill="none"
            className={props.className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 21C7.0275 21 3 16.9725 3 12C3 7.0275 7.0275 3 12 3C16.9725 3 21 7.0275 21 12C21 16.9725 16.9725 21 12 21ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"
                    fill="black"
                />
                <circle
                    id="Ellipse 5"
                    cx="12"
                    cy="12"
                    r="5.5"
                    stroke={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default TargetIcon;
