interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
}

const LogoutIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 18C21.1 18 21.99 17.1 21.99 16L22 6C22 4.89001 21.1 4 20 4H4C2.89001 4 2 4.89001 2 6V16C2 17.1 2.89001 18 4 18H0V20H24V18H20ZM4 16V6H20V16.01L4 16ZM7 15C7.56 12.33 9.10999 9.67001 13 9.13V7L17 10.73L13 14.47V12.28C10.22 12.28 8.39001 13.13 7 15Z"
                    fill={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default LogoutIcon;
