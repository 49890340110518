interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
}

const DownArrowIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 22}
            height={props.height || 10}
            className={props.className}
            viewBox="0 0 22 10"
            fill="none"
        >
            <path
                d="M10.6429 8.5L10.0349 9.294C10.37 9.55054 10.8301 9.56913 11.1847 9.34046L10.6429 8.5ZM22.0419 2.34046C22.506 2.04119 22.6397 1.4223 22.3405 0.958125C22.0412 0.493952 21.4223 0.360272 20.9581 0.659541L22.0419 2.34046ZM2.10791 0.705993C1.66939 0.370253 1.04173 0.453571 0.705995 0.892088C0.370255 1.3306 0.453573 1.95826 0.89209 2.294L2.10791 0.705993ZM11.1847 9.34046L22.0419 2.34046L20.9581 0.659541L10.101 7.65954L11.1847 9.34046ZM11.2508 7.70599L2.10791 0.705993L0.89209 2.294L10.0349 9.294L11.2508 7.70599Z"
                fill={props.color || '#585970'}
            />
        </svg>
    );
};

export default DownArrowIcon;
