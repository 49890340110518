interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    strokeWidth?: number;
}

const DenyIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 14}
            height={props.height || 14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <line
                x1="1.99818"
                y1="11.8805"
                x2="11.9394"
                y2="1.93927"
                stroke={props.color || 'white'}
                strokeWidth={props.strokeWidth || '2'}
            />
            <line
                y1="-1.5"
                x2="14.059"
                y2="-1.5"
                transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 10.9414 13.2253)"
                stroke={props.color || 'white'}
                strokeWidth={props.strokeWidth || '2'}
            />
        </svg>
    );
};

export default DenyIcon;
