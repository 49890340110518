import { useEffect, useRef, useState } from 'react';
import styles from './date-picker.module.scss';
import LeftArrowIcon from '../../icons/left-arrow.icon';
import RightArrowIcon from '../../icons/right-arrow.icon';

const DAYS_OF_THE_WEEK = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

interface IDate {
    day: number;
    date: number;
    month: number;
    year: number;
}

interface Props {
    visible: boolean;
    onChange: (date: Date) => void;
    pastAllowed?: boolean;
    onBlur: () => void;
}

const DatePicker = (props: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [today, setToday] = useState<Date>();
    const [daysOfMonth, setDaysOfMonth] = useState<IDate[]>([]);
    const [activeDate, setActiveDate] = useState<Date>();

    useEffect(() => {
        getToday();
    }, []);

    useEffect(() => {
        if (activeDate) {
            getDaysOfCurrentMonth();
        }
    }, [activeDate]);

    useEffect(() => {
        if (props.visible) {
            containerRef.current?.focus();
        }
    }, [props.visible]);

    const getToday = () => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        setToday(date);
        setActiveDate(date);
    };

    const getDaysOfCurrentMonth = () => {
        const date = new Date(activeDate!.getTime());
        date.setDate(1); // Set it to the first day of the month
        date.setHours(0, 0, 0, 0); // Set date to midnight: timestamp 00:00:00

        const listOfDays = [];
        const currentMonth = activeDate!.getMonth();

        while (date.getMonth() === currentMonth) {
            listOfDays.push({
                day: date.getDay(),
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            });

            date.setDate(date.getDate() + 1);
        }

        setDaysOfMonth(listOfDays);
    };

    const goToNextMonth = () => {
        const date = new Date(activeDate!.getTime());
        date.setDate(1);
        date.setMonth(date.getMonth() + 1);
        setActiveDate(date);
    };

    const goToPreviousMonth = () => {
        const date = new Date(activeDate!.getTime());
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        setActiveDate(date);
    };

    const checkPastAllowed = (date: IDate) => {
        const dateObject = new Date(date.year, date.month, date.date);
        if (props.pastAllowed !== undefined && !props.pastAllowed) {
            return !(dateObject < today!);
        }

        return true;
    };

    const selectDate = (date: IDate) => {
        const dateObject = new Date(date.year, date.month, date.date);
        if (checkPastAllowed(date)) {
            props.onChange?.(dateObject);
        }
    };

    return (
        <div
            className={`${styles.mainContainer} ${
                props.visible ? styles.visible : ''
            }`}
            ref={containerRef}
            tabIndex={1}
            onBlur={() => props.onBlur()}
        >
            <header>
                <div
                    className={styles.iconContainer}
                    onClick={goToPreviousMonth}
                >
                    <LeftArrowIcon width={5} />
                </div>
                <h3>
                    {MONTHS[activeDate?.getMonth() || 0]}{' '}
                    {activeDate?.getFullYear()}
                </h3>
                <div className={styles.iconContainer} onClick={goToNextMonth}>
                    <RightArrowIcon width={5} />
                </div>
            </header>

            <ul className={styles.daysOfWeekList}>
                {DAYS_OF_THE_WEEK.map((day, idx) => (
                    <li key={idx}>{day}</li>
                ))}
            </ul>

            <div className={styles.daysContainer}>
                {daysOfMonth.map((day, idx) => {
                    return (
                        <div
                            key={idx}
                            className={`${styles.dayItem} ${
                                checkPastAllowed(day) ? '' : styles.notAllowed
                            }`}
                            style={{
                                marginLeft: `${idx === 0 ? 30 * day.day : 0}px`,
                            }}
                            onClick={() => selectDate(day)}
                        >
                            {day.date}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DatePicker;
