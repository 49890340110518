interface Props {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
}

const PhoneIcon = (props: Props) => {
    return (
        <svg
            width={props.width || 24}
            height={props.height || 24}
            viewBox="0 0 24 24"
            fill="none"
            className={props.className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM13 7H11V9H13V7ZM13 11H11V17H13V11ZM7 21H17V20H7V21ZM17 18H7V6H17V18ZM7 3V4H17V3H7Z"
                    fill={props.color || 'black'}
                />
            </g>
        </svg>
    );
};

export default PhoneIcon;
