import styles from './menu.module.scss';
import LeftArrowIcon from '../../icons/left-arrow.icon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EmailIcon from '../../icons/email.icon';
import PushIcon from '../../icons/push.icon';
import SmsIcon from '../../icons/sms.icon';
import LogoutIcon from '../../icons/logout.icon';

interface Props {
    onLogout: () => void;
}

const Menu = (props: Props) => {
    const [expanded, setExpanded] = useState(true);

    const toggleMenu = () => {
        setExpanded(!expanded);
    };

    const logOut = () => {
        props.onLogout();
    };

    return (
        <nav id={styles.menu} className={expanded ? styles.expanded : ''}>
            <header>PNS Web</header>
            <ul>
                <li>
                    <Link to={'/sms'}>
                        <SmsIcon color={'white'} width={20} />
                        <span>Mensajería SMS</span>
                    </Link>
                </li>
                <li>
                    <Link to={'/push'}>
                        <PushIcon color={'white'} width={20} />
                        <span>Notificaciones push</span>
                    </Link>
                </li>
                <li>
                    <Link to={'/emails'}>
                        <EmailIcon color={'white'} width={20} />
                        <span>Email</span>
                    </Link>
                </li>
                <li className={styles.logout} onClick={logOut}>
                    <LogoutIcon color={'white'} width={20} />
                    <span>Cerrar sesión</span>
                </li>
            </ul>
            <footer onClick={toggleMenu}>
                <LeftArrowIcon
                    color={'white'}
                    height={14}
                    width={28}
                    strokeWidth={2}
                    className={styles.toggleIcon}
                />
            </footer>
        </nav>
    );
};

export default Menu;
