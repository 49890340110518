export interface GlobalStateType {
    accessToken: string;
}

export interface Payload {
    action: string;
    data: any;
}

export const globalState: GlobalStateType = {
    accessToken: localStorage.getItem('accessToken') || '',
};

export const reducer = (
    state: GlobalStateType,
    payload: Payload
): GlobalStateType => {
    switch (payload.action) {
        case 'login':
            storeAccessToken(payload.data);
            return {
                ...state,
                accessToken: payload.data,
            };

        case 'logout':
            removeAccessToken();
            return {
                ...state,
                accessToken: '',
            };
        default:
            return state;
    }
};

const storeAccessToken = (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken);
};

const removeAccessToken = () => {
    localStorage.removeItem('accessToken');
};
